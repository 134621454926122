import { actionType } from '../actions';
const initialState = {
    user: {}
}

let loggedInUserDetail = function (state = { user: initialState.user }, action) {
    switch (action.type) {
        case actionType.LOGED_IN_USER_DETAIL:
            if (action.value["user"]) {
                state = Object.assign({}, state, action.value);
            }
            else {
                state = initialState;
            }
            return state;
        default:
            return state;
    }
}
let setLogout = function (state = {}, action) {
    if (action.type === actionType.SET_LOGOUT) {
        state = Object.assign({}, state, { logout: action.value });
    }
    return state;
}
export {
    loggedInUserDetail,
    setLogout
}