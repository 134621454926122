import * as React from 'react';
import { Drawer, Divider, ListItem, ListItemIcon, ListItemText, withStyles, IconButton } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@material-ui/icons'; // Replace with your desired icons
import { styles } from './styles';
import api from '../redux/httpUtil/serverApi';

import '../App.css';
const classNames = require('classnames');

const CopyRight = () => {
    return (
        <div className="copy-right">
            {'Copyright © Spraxa '}
            {new Date().getFullYear()}
            {'.'}
        </div>
    );
}

class AppDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false,
        };
    }

    handleDrawer = () => {
        this.setState((prevState) => ({
            drawerOpen: !prevState.drawerOpen,
        }));
    };

    handleImageClick = () => {
        this.setState({
            drawerOpen: true,
        });
    };

    render() {
        const { authentication, classes, routes, clientId } = this.props;
        const { drawerOpen } = this.state;
        let logoUrl = "./images/visitor.png";

        if (clientId) {
            let logoPath = api.baseUrl;
            logoUrl = logoPath + clientId + '.png';
        }

        return (
            <Drawer
                hidden={!authentication}
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
                }}
                open={drawerOpen}
            >
                <div className={classes.toolbar}>
                <img src={logoUrl} className="img-responsive" alt='Logo' onClick={this.handleImageClick} />
                    {drawerOpen && (
                        <IconButton className="drawer-icon" onClick={this.handleDrawer}>
                            {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    )}
                   
                    <div className={classes.flexGrow} /> {/* Add a flex container to push the icon to the right */}
                </div>
                <Divider />
                {routes.map((route, index) => (
                    route.isMenu ? (
                        <NavLink key={index} exact={true} activeClassName={classes.current} className={classes.link} to={route.path} >
                            <ListItem button={true}>
                                <ListItemIcon>
                                    {route.icon()}
                                </ListItemIcon>
                                <ListItemText primary={route.title} />
                            </ListItem>
                        </NavLink>
                    ) : null
                ))}
                <Divider />
                <CopyRight />
            </Drawer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AppDrawer);
