import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import { styles } from './styles';
import { connect } from 'react-redux';
import AppDrawer from './App.Drawer';
import Login from '../screens/Login';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';
import Loader from '../components/Loader';
import routes from '../routes';

let authenticated = false;

const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => {
        return (
            authenticated
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/'
                }} />
        )
    }} />
}
class MiniDrawer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            notificationEl: null
        };
    }
    getRoutes(data) {
        let validRoutes = [];
        const role = data.user && data.user.RoleId ? data.user.RoleId.Role : data.user.Role;
        for (let index = 0, len = routes.length; index < len; index++) {
            const route = routes[index];
            if (!authenticated || role === '' || route.permission.indexOf(role) < 0) {
                continue;
            }
            validRoutes.push(route);
        }
        if (validRoutes.length === 0) {
            validRoutes.push({ path: '/' });
        }
        return validRoutes;
    }
    render() {
        const { classes, data, logout, initialValues } = this.props;
        authenticated = logout ? false : data && data.user && data.user._id;
        const isFetching = initialValues.isFetching;
        const validRoutes = this.getRoutes(data);
        return (
            <div className={classes.root}>
                {logout ? <Redirect to='/' /> : null}
                {authenticated ? <>
                    {isFetching && <Loader isOpen={isFetching} />}
                    <AppDrawer
                        drawerOpen={true}
                        authentication={true}
                        routes={validRoutes}
                        clientId={data.user.ClientId && data.user.ClientId._id}
                    /> </> : null}

                <Switch>
                    <Route key="/" path="/" exact component={Login} />
                    <Route name='forgotPassword' path="/forgotPassword" exact component={ForgotPassword} />
                    <Route name='resetPassword' path="/resetPassword/:id?" exact component={ResetPassword} />
                    <Route key="/redirect/:redirectTo?" path="/redirect/:redirectTo?" component={Login} />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {
                            validRoutes.map((route) => {
                                const Component = route.component;
                                const hash = window.location.hash.replace('#/', '');
                                const redirectPath = hash === '' || logout ? '' : 'redirect/' + hash;
                                return (
                                    Component ?
                                        <PrivateRoute key={route.path} path={route.path} component={Component} exact={route.exact} />
                                        : <Redirect key={route.path} to={{
                                            pathname: '/' + redirectPath
                                        }} />
                                );
                            })
                        }
                    </main>
                </Switch>

            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        data: state.loggedInUserDetail,
        logout: state.setLogout.logout,
        initialValues: state.loginAction
    }
}
var miniDrawerModule = connect(mapStateToProps)(MiniDrawer);
export default withStyles(styles, { withTheme: true })(miniDrawerModule);
