import GridBase from '../components/GridBase';
import { connect } from 'react-redux';
import { getClients } from '../redux/actions/httpRequest'
import util from '../Util/index';
import moment from 'moment';
class ClientList extends GridBase {
    idColumn = "_id";
    title = 'Clients';
    showFormToSuperAdmin = true;
    formRoute = util.route.addClient;
    rowHeight = 40;
    defaultSort = 'ClientName';
    columns = [
        { headerName: "Client Name", field: "ClientName", defaultSort: 'asc', width: 150 },
        { headerName: "Email", field: "Email", width: 220 },
        { headerName: "Phone Number", field: "PhoneNumber", width: 130 },
        { headerName: "Address", field: "Address", width: 130 },
        { headerName: "Created On", field: "createdAt", width: 150, renderCell: params => { return params.value ? moment(params.value).format(util.dateTimeFormatAmPm) : '' } },
        { headerName: "Updated On", field: "updatedAt", width: 150, renderCell: params => { return params.value ? moment(params.value).format(util.dateTimeFormatAmPm) : '' } }
    ]
};

ClientList.defaultProps = {
    action: getClients
};

function mapStateToProps(state) {
    return {
        data: state.clients.data,
        loggedInUserDetail: state.loggedInUserDetail
    }
};
var ClientModule = connect(mapStateToProps)(ClientList);
export default ClientModule;