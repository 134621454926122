import React from 'react';
import GridBase from '../components/GridBase';
import PrintIcon from '@mui/icons-material/Print';
import { connect } from 'react-redux';
import { getVisitors } from '../redux/actions/httpRequest'
import util from '../Util/index';
import moment from 'moment';
import api from '../redux/httpUtil/serverApi';
import { Button } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';

class VisitorList extends GridBase {
  idColumn = "_id";
  title = "Visitors";
  showFormToSuperAdmin = false;
  defaultSort = "CheckIn";
  fromVisitor = true;
  rowHeight = 50;
  formRoute = util.route.scheduleVisit;
  addButtonText = "Schedule Visit";
  hideColumn = true;
  sortOrder = 'desc';
  columns = [
    {
      field: 'badge',
      headerName: 'Badge',
      width: 40,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <PrintIcon onClick={() => this.openDialog(params.row)} style={{ cursor: 'pointer' }} />
        </div>
      )
    },
    {
      field: 'url',
      headerName: 'Photo',
      renderCell: (params) => {
        let imageUrl = `${api.baseUrl}getFace/${params.row._id}/?v=${Date.now()}`;
        return (
          <Avatar
            src={imageUrl}
            alt='face'
          />
        );
      },
      filtering: false
    },
    { headerName: "Name", field: "FullName", width: 130 },
    { headerName: "Email", field: "Email", width: 220 },
    { headerName: "Phone Number", field: "PhoneNumber", width: 130 },
    { headerName: "From Company", field: "CompanyName", width: 130 },
    { headerName: "Person To Meet", field: "PersonToMeet", width: 130 },
    { headerName: "Reason", field: "Reason", width: 150 },
    { headerName: "Scheduled On", field: "ScheduledOn", width: 150, renderCell: params => { return params.value ? moment(params.value).format(util.dateTimeFormat) : '' } },
    { headerName: "Duration (Min)", field: "Duration", width: 120, type: 'numeric' },
    { headerName: "Check In", field: "CheckIn", width: 150, renderCell: params => { return params.value ? moment(params.value).format(util.dateTimeFormat) : '' } },
    { headerName: "Check Out", field: "CheckOut", width: 150, renderCell: params => { return params.value ? moment(params.value).format(util.dateTimeFormat) : '' } },
    { headerName: "Client Name", field: "ClientName", width: 150 },
    {
      headerName: "Status", field: "Status", width: 130,   renderCell: params => {
        const isScheduledExpired = params.row.ScheduledOn && moment(params.row.ScheduledOn) < moment();
        const isCheckInOutNull = params.row.CheckIn === null && params.row.CheckOut === null;
        if (isScheduledExpired && isCheckInOutNull) {
          return "Expired";
        } else {
          return params.row.Status;
        }
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const isScheduledAfterToday = params.row.ScheduledOn ? moment(params.row.ScheduledOn) > moment() : true;

        return (
          params.row.CheckOut === null &&
          params.row.CheckIn !== null &&
          isScheduledAfterToday && (
            <Button
              variant="contained"
              color="primary"
              
              onClick={(event) => {
                event.stopPropagation();
                this.openCheckoutDialog(params.row);
              }}
              style={{ padding: '5px', fontSize:'10px' }}
            >
              Checkout
            </Button>
          )
        );
      }
    }
  ]
}

VisitorList.defaultProps = {
  action: getVisitors
}

function mapStateToProps(state) {
  return {
    data: state.visitors && state.visitors.data,
    loggedInUserDetail: state.loggedInUserDetail
  }
}
var VisitorModule = connect(mapStateToProps)(VisitorList);
export default VisitorModule;