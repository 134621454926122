export default {
    Roles: {
        SuperAdmin: 'Super Admin',
        ClientAdmin: 'Client Admin',
        User: 'User'
    },
    reasonOption: [
        { label: 'Visit', value: 'Visit' },
        { label: 'Business Meeting', value: 'Business Meeting' },
        { label: 'Maintenance', value: 'Maintenance' },
        { label: 'Interview', value: 'Interview' },
        { label: 'Others', value: 'Others' }
    ]
}