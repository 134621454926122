import FetchApi from '../httpUtil/fetchApi';
import api from '../httpUtil/serverApi';
import * as actionType from './index';

export let loginAction = new FetchApi({
  requestType: 'Login',
  url: api.LOGIN
});


export let reLogin = new FetchApi({
  requestType: 'reLogin',
  url: api.RE_LOGIN
});

export let getGridDataRequest = new FetchApi({
  requestType: 'getGridData',
  url: api.CASE
})

export let getVisitors = new FetchApi({
  requestType: actionType.actionType.VISITOR_LIST_DATA,
  url: api.VISITORLIST
})

export let getClients = new FetchApi({
  requestType: actionType.actionType.CLIENT_LIST_DATA,
  url: api.CLIENTLIST
})

export let getRoles = new FetchApi({
  requestType: actionType.actionType.ROLE_LIST_DATA,
  url: api.ROLELIST
})

export let getUsers = new FetchApi({
  requestType: actionType.actionType.GET_USER,
  url: api.GET_USER
})

export let userAction = new FetchApi({
  requestType: actionType.actionType.SAVE_USER,
  url: api.USER_ACTION
})

export let saveClient = new FetchApi({
  requestType: actionType.actionType.SAVE_CLIENT,
  url: api.SAVE_CLIENT
});

export let deleteClient = new FetchApi({
  requestType: actionType.actionType.DELETE_CLIENT,
  url: api.DELETE_CLIENT
});

export let employee = new FetchApi({
  requestType: actionType.actionType.EMPLOYEE,
  url: api.EMPLOYEE
});

export let scheduleVisit = new FetchApi({
  requestType: actionType.actionType.SCHEDULE_VISIT,
  url: api.SCHEDULE_VISIT
});

export let changePassword = new FetchApi({
  requestType: actionType.actionType.CHANGE_PASSWORD,
  url: api.CHANGE_PASSWORD
});

export let emergencyNotification = new FetchApi({
  requestType: actionType.actionType.EMERGENCY_NOTIFICATION,
  url: api.EMERGENCY_NOTIFICATION
});

export let cancelVisit = new FetchApi({
  requestType: actionType.actionType.CANCEL_VISIT,
  url: api.CANCEL_VISIT
});

export let combo = new FetchApi({
  requestType: actionType.actionType.COMBO,
  url: api.COMBO
});


export let emailTemplate = new FetchApi({
  requestType: actionType.actionType.EMAIL_TEMPLATE,
  url: api.EMAIL_TEMPLATE
});