import React from 'react';
import '../App.css';

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);
const FileInput = ({
    input: { value: omitValue, onChange, onBlur, ...inputProps },
    meta: omitMeta,
    ...props
}) => {
    return (
        <>
            <label htmlFor="file" className="custom-file-upload"><i className="fa fa-file" aria-hidden="true"></i>Upload Logo</label>
            <input
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                accept="image/*"
                type="file"
                name="file" 
                id="file"
                {...props.input}
                {...inputProps}
                {...props}
            /><br /><br />
        </>

    );
};

export default FileInput;