import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextField, DatePicker, TimePicker, SelectField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field, change } from 'redux-form';
import Validator from '../Util/Validator';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { scheduleVisit, cancelVisit } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader'
import util from '../Util/index';
import DefaultLayout from '../components/DefaultLayout';
import consts from '../Util/consts';
import MenuItem from 'material-ui/MenuItem';
import api from './../redux/httpUtil/serverApi';
import ReduxReactSelect from '../components/ReduxField/ReduxReactSelect';
import moment from 'moment';
class ScheduleVisit extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isFetching: null
        }
    }
    componentWillMount() {
        const id = this.props.match.params.id,
            visitId = this.props.match.params.visitId;
        if (id && id !== '0' && this.props.visitors) {
            const data = this.props.visitors.data.filter(e => { return (e._id === id && e.VisitId === visitId) });
            const { FullName, Email, PhoneNumber, ScheduledOn, Duration, CompanyName, PersonToMeet, PersonToMeetId, Reason, Message, ClientId } = data[0];
            this.props.initialize({ FullName: FullName, ClientId: ClientId, Email: Email, PhoneNumber: PhoneNumber, ScheuledDate: new Date(ScheduledOn), Time: new Date(ScheduledOn), Duration: Duration, CompanyName: CompanyName, PersonToMeet: { label: PersonToMeet, value: PersonToMeetId == null ? PersonToMeet : PersonToMeetId }, Reason: Reason, Message: Message === "undefined" ? "" : Message });
        } else {
            const { loggedInUserDetail } = this.props;
            if (loggedInUserDetail && loggedInUserDetail.user) {
                this.props.initialize({ Duration: 10, PersonToMeet: loggedInUserDetail.user.Name });
            }
        }
    }
    onSubmit = (values) => {
        if (typeof values.PersonToMeet === "object") {
            values.PersonToMeetId = values.PersonToMeet.value || '';
            values.PersonToMeet = values.PersonToMeet.label || '';
        }
        this.setState({ isFetching: true });
        const me = this;
        const id = this.props.match.params.id,
            visitId = this.props.match.params.visitId;
        if (id && id !== '0') {
            values.id = id;
            if (this.props.visitors) {
                const data = this.props.visitors.data.filter(e => { return e._id === id && e.VisitId === visitId });
                values.visitId = data[0].VisitId;
            }
        }
        values.ScheuledDate.setMinutes(values.Time.getMinutes());
        values.ScheuledDate.setHours(values.Time.getHours());
        values.action = 'schedule';
        values.utcOffset = moment().utcOffset();
        me.props.dispatch(scheduleVisit.request(values, null, null, (response) => {
            let { success, message } = response;
            this.setState({ isFetching: false });
            if (success) {
                me.props.history.push(util.route.visitor);
            } else {
                swal({
                    title: "Error",
                    text: message,
                    icon: "error"
                });
            }
        }));
    }

    onCancelClick = () => {
        this.props.reset();
        this.props.history.push(util.route.visitor);
    }

    formatDate = (date) => {
        return moment(date).format(util.dateFormat);
    };

    onCancelVisit = () => {
        const id = this.props.match.params.id,
            visitId = this.props.match.params.visitId;
        let params = {};
        if (id && id !== 0) {
            params.id = id;
            if (this.props.visitors) {
                const data = this.props.visitors.data.filter(e => { return e._id === id && e.VisitId === visitId });
                let { VisitId, Email, FullName, PersonToMeetId } = data[0];
                params.visitId = VisitId;
                params.email = Email;
                params.name = FullName;
                params.personToMeetId = PersonToMeetId;
                params.utcOffset = moment().utcOffset();
            }
            this.props.dispatch(cancelVisit.request(params, null, null, (response) => {
                let { success, message } = response;
                this.props.history.push(util.route.visitor);
                swal({
                    title: success ? 'Success' : "Error",
                    text: message,
                    icon: success ? "success" : "error"
                });
            }))
        }
    }
    handleBlur = (event) => {
        event.preventDefault();
    };
    handleInputChange = (newValue) => {
        change('scheduleForm', 'PersonToMeet', newValue);
    };
    onlyNumbers = value => value.replace(/[^\d]/g, '');
    normalizePhoneNumber = value => {
        if (!value) {
          return value;
        }
    
        // Keep only numerical characters
        const onlyNumbers = this.onlyNumbers(value);
    
        // Limit the length to a minimum of 1 and a maximum of 12
        return onlyNumbers.slice(0, 12);
      };
    render() {
        const { handleSubmit, match } = this.props,
            id = match.params.id;
        const newUser = id && id === '0';
        const { isFetching } = this.state;
        return (
            <DefaultLayout title={"Schedule Visit"}>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <form onSubmit={handleSubmit(this.onSubmit)} >
                        <Field fullWidth name="FullName" component={TextField} hintText="Name" maxLength="50" floatingLabelText="Name" validate={[Validator.required]} />
                        <Field fullWidth name="Email" component={TextField} hintText="Email" floatingLabelText="Email" validate={[Validator.required, Validator.email]} />
                        <Field fullWidth name="PhoneNumber"  component={TextField} hintText="Phone Number" floatingLabelText="Phone Number"  normalize={this.normalizePhoneNumber}  validate={[Validator.required, Validator.contactNo, Validator.contactLength]} />
                        <div className="schedule-dateTime">
                            <Field name="ScheuledDate" component={DatePicker} minDate={new Date()} hintText="Scheduled Date" floatingLabelText="Scheduled Date"
                                formatDate={this.formatDate} validate={[Validator.required]} className="field-space" />
                            <Field name="Time" component={TimePicker} props={{ format: "ampm" }} hintText="Scheduled Time" floatingLabelText="Scheduled Time" validate={[Validator.required, Validator.minTime]} />
                            <Field fullWidth name="Duration" type="number" component={TextField} hintText="Duration (Min)" floatingLabelText="Duration" validate={[Validator.required, Validator.duration, Validator.contactNo]} />
                        </div>
                        <Field fullWidth name="CompanyName" component={TextField} hintText="Company Name" maxLength="50" floatingLabelText="Company Name" validate={[Validator.required]} /><br /><br />
                        <Field fullWidth name="PersonToMeet" config={{ method: 'POST', url: api.GET_USER, fieldName: 'Name' } }
                            onChange={this.handleInputChange} onBlur={this.handleBlur} component={ReduxReactSelect} hintText="Person To Meet"  validate={[Validator.required]} maxLength="50"   style={{ backgroundColor: 'white' }}  // Set white background color for the input
                            dropdownStyle={{ backgroundColor: 'white' }}  floatingLabelText="Person to meet" />

                        <Field fullWidth name="Reason" component={SelectField} hintText="Reason" floatingLabelText="Reason" >
                            {consts.reasonOption.map(option => (
                                <MenuItem primaryText={option.label} key={option.value} value={option.value}> </MenuItem>
                            ))}
                        </Field>
                        <Field fullWidth name="Message" component={TextField} multiLine={true}  hintText="Message" maxLength="500" floatingLabelText="Message" />
                        <div style={{ display: 'flex' }}>
                            <Button variant="contained" color="primary" type="submit">{!newUser ? 'Update' : 'Save'}</Button>&nbsp;&nbsp;
                            {!newUser && <Button variant="contained" color="primary" type="button" onClick={this.onCancelVisit} >Cancel Visit</Button>}&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="contained" color="primary" type="button" onClick={this.onCancelClick}>Cancel</Button>
                        </div>
                    </form>
                </MuiThemeProvider>
            </DefaultLayout >
        )
    }
}

ScheduleVisit = reduxForm({
    form: 'scheduleForm'
})(ScheduleVisit);


function mapStateToProps(state) {
    return {
        data: state.users && state.users.data,
        loggedInUserDetail: state.loggedInUserDetail,
        visitors: state.visitors && state.visitors.data,
    };
}

ScheduleVisit = connect(mapStateToProps)(ScheduleVisit);
export default ScheduleVisit;