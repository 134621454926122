import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './App.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import AppNavBar from './navigation/App.Bar';
import { Router } from 'react-router';
import blue from '@material-ui/core/colors/blue';
import { pink } from '@material-ui/core/colors';
import { createHashHistory } from 'history';
import { initializeLogger } from './Util/Logger';
export const history = createHashHistory();

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: pink
    }
})

initializeLogger();
ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <MuiThemeProvider theme={theme}>
                <AppNavBar />
            </MuiThemeProvider>
        </Router>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
