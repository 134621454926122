import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { emailTemplate, combo } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader';
import DefaultLayout from '../components/DefaultLayout';
import Validator from '../Util/Validator';
import MenuItem from 'material-ui/MenuItem';
import { TextField, SelectField } from 'redux-form-material-ui';
import TextEditor from '../components/ReduxField/TextEditor';
import '../App.css';

class EmailTemplateSettings extends PureComponent {

    componentDidMount() {
        let params = { comboType: ["VisitorInfo", "ScheduledVisit"] }
        this.props.dispatch(combo.request(params, null, null, (response) => {
            let { success, message, data } = response;
            if (!success) {
                swal({
                    title: "Error",
                    text: message,
                    icon: "error"
                });
            } else if (data) {
                let params = { action: "load", templateType: data[0][0]._id }
                this.props.dispatch(emailTemplate.request(params, null, null, (response) => {
                    let { success, message, data } = response;
                    if (success && data) {
                        let { templateId, subject, body } = data;
                        this.props.initialize({ templateType: templateId, subject: subject, body: body });
                    } else if (!success) {
                        swal({
                            title: "Error",
                            text: message,
                            icon: "error"
                        });
                    }
                }))
            }
        }))
    }

    onChange = (data) => {
        let params = { action: "load", templateType: data }
        this.props.dispatch(emailTemplate.request(params, null, null, (response) => {
            let { success, message, data } = response;
            if (success && data) {
                let { templateId, subject, body } = data;
                this.props.initialize({ templateType: templateId, subject: subject, body: body });
            } else if (!success) {
                swal({
                    title: "Error",
                    text: message,
                    icon: "error"
                });
            }
        }))
    }

    static flatten(arr) {
        return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(EmailTemplateSettings.flatten(val)) : acc.concat(val), []);
    }

    onSave = (values) => {
        values.action = "save";
        this.props.dispatch(emailTemplate.request(values, null, null, (response) => {
            let { success, message } = response;
            swal({
                title: success ? "Success" : "Error",
                text: message,
                icon: success ? "success" : "error"
            });
        }));
    }

    render() {
        let { handleSubmit, emailTemplate, combo } = this.props,
            isFetching = emailTemplate.isFetching || emailTemplate.isFetching;
        const title = "Update Settings";

        const flatCombo = EmailTemplateSettings.flatten(combo || []);
        return (
            <div>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <div className='changePasswordTitle'>Email Template Settings</div>
                    <form onSubmit={handleSubmit(this.onSave)} >
                        <Field fullWidth name="templateType" component={SelectField} hintText="Select template type" floatingLabelText="Select template type" onChange={this.onChange} validate={[Validator.required]}>
                            {flatCombo && flatCombo.length > 0 && flatCombo.map(e => (
                                <MenuItem primaryText={e.displayValue} key={e._id} value={e._id}> </MenuItem>
                            ))}
                        </Field>
                        <Field fullWidth name="subject" component={TextField} multiLine={true} rows={2} hintText="Subject" maxLength="100" floatingLabelText="Subject" validate={[Validator.required]} />
                        <Field fullWidth name="body" component={TextEditor} hintText="Body" floatingLabelText="Body" validate={[Validator.required]} /><br />
                        <Button variant="contained" color="primary" type="submit">Save</Button>
                    </form>
                </MuiThemeProvider>
                </div>
        )
    }
}

EmailTemplateSettings = reduxForm({
    form: 'emailTemplateSettingsForm'
})(EmailTemplateSettings);
function mapStateToProps(state) {
    return {
        emailTemplate: state.emailTemplate,
        combo: state.combo.data && state.combo.data.data
    };
}
EmailTemplateSettings = connect(mapStateToProps)(EmailTemplateSettings);
export default EmailTemplateSettings;