import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import DefaultLayout from '../components/DefaultLayout';
import GeneralSettings from '../screens/GeneralSettings';
import PreferencesSettings from '../screens/PreferenceSettings';
import EmailTemplateSettings from '../screens/EmailTemplateSettings';
import '../App.css';

class Settings extends PureComponent {
    render() {
        let { loggedInUserDetail } = this.props,
            role = loggedInUserDetail.Role ? loggedInUserDetail.Role : loggedInUserDetail.RoleId.Role,
            showPreferenceSettings = role === "Client Admin",
            showEmailTemplateSettings = (role === "Client Admin");
        const title = "Update Settings";
        return (
            <DefaultLayout title={title}>
                <MuiThemeProvider>
                    {showPreferenceSettings &&
                        <>
                            <PreferencesSettings />
                            <br />
                        </>}
                    <GeneralSettings /> <br />
                    {showEmailTemplateSettings &&
                         <EmailTemplateSettings />}
                   
                </MuiThemeProvider>
            </DefaultLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        loggedInUserDetail: state.loggedInUserDetail.user
    };
}
Settings = connect(mapStateToProps)(Settings);
export default Settings;