import moment from 'moment';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
let Validator = {
    required: value => (value == null || !value ? 'Required' : undefined),
    email: value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined,
    passwordMatch: (value, allValues) => value !== allValues.NewPassword ? 'Password Mismatch' : undefined,
    contactNo: value => value && !/^[0-9]+$/i.test(value) ? 'Use numbers only' : undefined,
    contactLength: value => value && /^\d+$/.test(value) && value.length === 10 ? undefined : 'Invalid contact number',
    duration: value => value && (value <= 300 && value >= 1) ? undefined : 'Invalid Duration',
    passwordComplexity: (value) => value && (value === '*****' || strongRegex.test(value)) ? undefined : 'Password should contain at least one digit, one lower case, one upper case, one special symbol and minimum 8 characters',
    multipleEmailValidator: (value) => (value && /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/.test(value)) || value === "" ? undefined : "Invalid Email(s)",
    minTime: (value, allValues) => moment(value, "HH:mm a").isBefore(new Date(), "HH:mm a") && moment(new Date()).format("DD/MM/YYYY") === moment(new Date(allValues.ScheuledDate)).format("DD/MM/YYYY") ? "Invalid Time" : undefined
}
export default Validator;