import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import Validator from '../Util/Validator';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { userAction } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../App.css';

class ResetPasswordForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            email: null
        }
    }
    componentWillMount() {
        const secretKey = this.props.match.params.id || null;
        if (secretKey) {
            const params = { secretKey: secretKey, action: 'validateLink' };
            this.props.dispatch(userAction.request(params, null, null, (response) => {
                let { success, message, data } = response;
                if (!success) {
                    this.props.history.push('/');
                    swal({
                        title: "Error",
                        text: message,
                        icon: "error"
                    });
                } else {
                    this.setState({ email: data.Email })
                }
            }));
        }
    }

    onSubmit = (values) => {
        values.email = this.state.email;
        values.action = 'resetPassword';
        this.props.dispatch(userAction.request(values, null, null, (response) => {
            const { success, message } = response;
            if (success) {
                this.props.history.push('/');
            }
            swal({
                title: success ? 'Success' : "Error",
                text: message,
                icon: success ? "success" : "error"
            });
        }));
    }

    render() {
        let { handleSubmit, userAction } = this.props,
            isFetching = userAction.isFetching;
        return (
            <>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        <Grid container spacing={3}>
                            <div className="login-grid">
                                <br />
                                <Grid item xs={12} align='center'>
                                    <img src={"./images/visitor.png"} width="20%" height="20%" alt='Visitor' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" color="inherit" align='center'>
                                        Account Recovery
                                         </Typography>
                                </Grid><br />
                                <Grid item xs={12} align='center'>
                                    <Field name="NewPassword" type="password" component={TextField} maxLength="20" hintText="New Password" floatingLabelText="New Password" validate={[Validator.required, Validator.passwordComplexity]} />
                                </Grid><br />
                                <Grid item xs={12} align='center'>
                                    <Field name="ConfirmPassword" type="password" component={TextField} maxLength="20" hintText="Confirm Password" floatingLabelText="Confirm Password" validate={[Validator.required, Validator.passwordMatch]} />
                                </Grid><br />
                                <Grid item xs={12} align='center'>
                                    <Button variant="contained" color="primary" type="submit" className='login-button'>Reset</Button>
                                </Grid>
                            </div>
                        </Grid>
                    </form>
                </MuiThemeProvider>
            </>
        )
    }
}

ResetPasswordForm = reduxForm({
    form: 'resetPasswordForm'
})(ResetPasswordForm);
function mapStateToProps(state) {
    return {
        userAction: state.userAction
    };
}
ResetPasswordForm = connect(mapStateToProps)(ResetPasswordForm);
export default ResetPasswordForm;