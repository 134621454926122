import promiseUtils from '../httpUtil/cancelableFetch';
const ACTION_STATUSES = ['START', 'SUCCESS', 'ERROR'];
const STATUS_CODE = {
    SUCCESS: 200,
    UNAUTHORIZED: 401
}

var defaultHandlers = {

    START: function (state, action, options) {
        return Object.assign({}, state, { isFetching: true, error: null });
    },

    SUCCESS: function (state, action, options) {
        if (action.options.start && !action.isFetching) {
            var allItems = [];
            allItems = action.options.start > 0 && state.data && action.options.isList ? state.data.records : [];
            action.json.records = allItems.concat(action.json.records);
            action.isFetching = true;
        }
        return Object.assign({}, state, { data: action.json, isFetching: false, type: action.type, error: null });
    },

    ERROR: function (state, action, options) {
        const { response } = action;
        let errorMessage = response;
        if (typeof response === 'object') {
            if (response.isTimedOut) {
                errorMessage = "Request timed out";
            } else if (response.isCanceled) {
                errorMessage = "Request canceled";
            } else if (response.message === "Failed to fetch") {
                errorMessage = "Please check your internet connection";
            }
        }
        return Object.assign({}, state, { isFetching: false, error: errorMessage });
    }
};


export default class AjaxAction {
    constructor(options) {
        Object.assign(this, options);
        let requestType = options.requestType;
        let actionTypes = {};
        let actions = {};
        let statuses = [].concat(ACTION_STATUSES);
        if (options.otherActionTypes) {
            statuses = statuses.concat(options.otherActionTypes)
        }
        for (const status of statuses) {
            var action = requestType + '_' + status
            actionTypes[requestType + '_' + status] = action;
            actions[status] = action;
        }
        this.actionTypes = actionTypes;
        this.actions = actions;
        this.reducer = this._createReducer(options);
    }

    _createReducer(options) {
        var requestType = options.requestType;

        var actions = options.actions || ACTION_STATUSES;

        var validActions = [];

        actions.forEach((action) => {
            var actionName = requestType + '_' + action;
            validActions.push(actionName);
        });

        for (var o in options.otherHandlers) {
            validActions.push(requestType + '_' + o);
        }

        let parser = this._createDefaultParser(options, actions);

        return function (state = { isFetching: false, data: null }, action) {
            if (validActions.indexOf(action.type) > -1) {
                return Object.assign({}, state, parser(state, action));
            }
            return state;
        }
    }

    _createDefaultParser(options, actions) {

        let customHandlers = Object.assign({}, defaultHandlers, options.handlers, options.otherHandlers);

        let handlerMap = {};

        for (var action in customHandlers) {
            var key = options.requestType + '_' + action;
            handlerMap[key] = customHandlers[action];
        };

        return function (state = { isFetching: false, data: {} }, action) {
            if (typeof handlerMap[action.type] === 'function') {
                return handlerMap[action.type](state, action, options);
            } else {
                return state;
            }
        }
    }

    dispose() {
        return (dispatch) => {
            return dispatch(this._onDispose({}, null));
        }
    }

    _onDispose(options, json) {
        return {
            type: this.actions.SUCCESS,
            options,
            json,
            receivedAt: Date.now()
        }
    }

    request(options, id, type, callbackFun) {
        return (dispatch, getState) => {
            return dispatch(this._fetch(options, id, type, callbackFun))
        }
    }

    _onStart(options) {
        return {
            type: this.actions.START,
            options
        }
    }

    _onSuccess(options, json) {
        return {
            type: this.actions.SUCCESS,
            options,
            json,
            receivedAt: Date.now()
        }
    }

    _onFailure(options, response) {
        return {
            type: this.actions.ERROR,
            options,
            response,
            receivedAt: Date.now()
        }
    }

    _getFormData(props) {
        var formData = new FormData();
        for (var key in props) {
            if (typeof props[key] === "string" || props[key] instanceof Date) {
                formData.append(key, props[key]);
            }
            else if (typeof props[key] === "object") {
                if ((props[key] && props[key].lastModifiedDate) || (props[key] && props[key].lastModified)) {
                    formData.append(key, props[key]);
                } else {
                    formData.append(key, JSON.stringify(props[key]));
                }
            } else {
                formData.append(key, JSON.stringify(props[key]));
            }
        }
        return formData;
    }

    _fetch(options, id, type, callbackFun) {
        // throw new Error("testing error email")
        var me = this;
        if (callbackFun) {
            me.callbackFun = callbackFun;
        }
        if (me.lastFetch) {
            me.lastFetch.cancel();
        }
        var url = this.url;
        if (id || id === 0) {
            url = url + '/' + id;
        }
        return dispatch => {
            dispatch(me._onStart(options))
            var timeout = options.timeout || 30000;

            let fetchOption = {
                method: type ? type : 'POST',
                ...Object.assign({}, type && type === "GET" ? {} : { body: this._getFormData(options, false) })
            };
            if (!options.ignoreCredential) {
                fetchOption.credentials = 'include';
            }

            var p = fetch(url, fetchOption);
            p = promiseUtils.makeCancelable(p, timeout);

            me.lastFetch = p;
            return p.then(response => {
                me.lastFetch = null;
                if (response.status === STATUS_CODE.SUCCESS) {
                    response.text().then(function (text) {
                        try {
                            var json = JSON.parse(text);
                            dispatch(me._onSuccess(options, json));
                            me.callbackFun && me.callbackFun(json);
                        }
                        catch (e) {
                            me.callbackFun && me.callbackFun(e);
                            dispatch(me._onFailure(options, e.message));
                            alert(e.message);
                        }
                    })

                } else {
                    if (response.status === STATUS_CODE.UNAUTHORIZED) {
                        dispatch(me._onFailure(options, "Session Timeout"));
                        alert("Please re-login to continue");
                    } else {
                        response.text().then(function (text) {
                            try {
                                var json = JSON.parse(text);
                                dispatch(me._onSuccess(options, json));
                                me.callbackFun && me.callbackFun(json);
                            }
                            catch (e) {
                                dispatch(me._onFailure(options, e.message));
                                alert(e.message);
                            }
                        })
                    }
                }
            }, function (err) {
                me.callbackFun && me.callbackFun(err);
                me.lastFetch = null;
                dispatch(me._onFailure(options, err));
            });
        };
    }
}
