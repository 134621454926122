import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

const style = {
    control: (base, state) => ({
        ...base,
        border: 'none !important',
        borderBottom: '1px solid #AFAFAF !important',
        borderRadius: '0px !important',
        boxShadow: 'none',
        "&:focus": {
            border: 'none !important'
        }
    })
};

class ReduxReactSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            options: [],
            personToMeet: null
        }
    }

    _getFormData(props) {
        var formData = new FormData();
        for (var key in props) {
            if (typeof props[key] === "string" || props[key] instanceof Date) {
                formData.append(key, props[key]);
            }
            else if (typeof props[key] === "object") {
                if ((props[key] && props[key].lastModifiedDate) || (props[key] && props[key].lastModified)) {
                    formData.append(key, props[key]);
                } else {
                    formData.append(key, JSON.stringify(props[key]));
                }
            } else {
                formData.append(key, JSON.stringify(props[key]));
            }
        }
        return formData;
    }
    promiseOptions = inputValue =>
        new Promise(async resolve => {
            const { config } = this.props;
            let value = inputValue;
            const response = await fetch(config.url, {
                method: config.method, credentials: 'include', body: this._getFormData({
                    params: { "orderBy": "Name", "dir": "asc", "filters": null }
                }, false)
            });
            if (response.status === 200) {
                let data = await response.json(), newData = [];
                if (data && data.data) {
                    data.data.forEach((e) => {
                        newData.push({ label: e.Name, value: e._id });
                    });
                    resolve(newData);
                }
            }
        });


    render() {
        const { input, meta } = this.props;
        let { touched, error, warning } = meta || {};
        return (
            <div>
                <AsyncCreatableSelect {...input}
                    cacheOptions defaultOptions
                    loadOptions={this.promiseOptions}
                    placeholder="Person To Meet"
                    styles={style}
                />
                {touched && ((error && <span className="required-color-red">{error}</span>) || (warning && <span className="warning-color">{warning}</span>))}
            </div>
        )
    }
}

export default ReduxReactSelect;
