import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import Validator from '../Util/Validator';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { saveClient, deleteClient } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader'
import util from '../Util/index';
import DefaultLayout from '../components/DefaultLayout';
import api from '../redux/httpUtil/serverApi';
import FileUpload from '../components/FileUpload';
import '../App.css';
const numericKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
class ClientForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            imagePreview: null
        }
    }
    handleKeyDown = (event) => {
        // Allow Backspace key
        if (event.key === 'Backspace') {
          return;
        }
      
        // Prevent input of non-numeric characters
        if (!numericKeys.includes(event.key)) {
          event.preventDefault();
        }
      };
    componentWillMount() {
        let id = this.props.match.params.id;
        if (id && id !== '0') {
            const data = this.props.data.filter(e => { return e._id === id });
            const { Email, ClientName, PhoneNumber, Address } = data[0];
            const clientId = data && data[0]._id;
            const path = api.baseUrl;
            const imageUrl = path + clientId + '.png';
            fetch(imageUrl).then((res) => {
                if (res.status !== 404) {
                    this.setState({
                        imagePreview: imageUrl
                    });
                }
            }).catch((err) => {
                swal({

                    title: "Error",
                    text: err.message || "Error processing the image",
                    icon: "error"
                });
            }).finally(() => {
                this.props.initialize({ ClientName: ClientName, Email: Email, PhoneNumber: PhoneNumber, Address: Address });
            });
        }
    }

    onSubmit = (values) => {
        let me = this;
        let id = this.props.match.params.id;
        const phoneNumber = values.PhoneNumber;
    
        // Check phone number length before submitting the form
        if (phoneNumber && phoneNumber.length > 10) {
            // Display error message on the page
            const errorMessageElement = document.getElementById('phoneNumberErrorMessage');
            if (errorMessageElement) {
                errorMessageElement.innerText = 'Invalid Contact Number';
            }
            return; // Do not submit the form if validation fails
        }
    
        if (id && id !== 0) {
            values.id = id;
        }
    
        me.props.dispatch(saveClient.request(values, null, null, (response) => {
            let { success, message } = response;
            if (success) {
                me.props.history.push(util.route.client);
            } else {
                swal({
                    title: "Error",
                    text: message,
                    icon: "error"
                });
            }
        }));
    }
    
    onCancelClick = () => {
        this.props.reset();
        this.props.history.push(util.route.client);
    }

    onDeleteClick = () => {
        let id = this.props.match.params.id,
            me = this;
        if (id && id !== 0) {
            me.props.dispatch(deleteClient.request({ id: id }, null, null, (response) => {
                let { success, message } = response;
                if (success) {
                    me.props.history.push(util.route.client);
                } else {
                    swal({
                        title: "Error",
                        text: message,
                        icon: "error",
                    });
                }
            }));
        }
    }
 
    onFileChange = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                imagePreview: reader.result
            });
        }
    }
    handlePhoneNumberChange = (e) => {
        const { change } = this.props;
        const phoneNumber = e.target.value;
    
        // Use normalize prop to restrict input to numeric characters only
        const normalizedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
    
        // Trigger onChange validation
        change('PhoneNumber', normalizedPhoneNumber);
    
        // Check phone number length and display error if it exceeds 10 digits
        if (normalizedPhoneNumber.length > 10) {
            change('PhoneNumber', ''); // Clear the field
    
            // Display error message on the page
            const errorMessageElement = document.getElementById('phoneNumberErrorMessage');
            if (errorMessageElement) {
                errorMessageElement.innerText = 'Invalid Contact Number';
            }
        } else {
            // Clear the error message if the length is within the limit
            const errorMessageElement = document.getElementById('phoneNumberErrorMessage');
            if (errorMessageElement) {
                errorMessageElement.innerText = '';
            }
        }
    };


    render() {
        let { handleSubmit, match, saveClient } = this.props,
            id = match.params.id,
            isFetching = saveClient.isFetching;
        const title = id && id !== '0' ? "Update Client" : "Add Client";
        let { imagePreview } = this.state;
        return (
            <DefaultLayout title={title}>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <form onSubmit={handleSubmit(this.onSubmit)} >
                        <Field fullWidth name="ClientName" component={TextField} maxLength="50" hintText="Client Name" floatingLabelText="Client Name" validate={[Validator.required]}  />
                        <Field fullWidth name="Email" component={TextField} hintText="Email" floatingLabelText="Email" validate={[Validator.email,Validator.required]} />
                        <Field
                            fullWidth
                            name="PhoneNumber"
                            component={TextField}
                            hintText="Phone Number"
                            floatingLabelText="Phone Number"
                            onChange={this.handlePhoneNumberChange}
                            onKeyDown={this.handleKeyDown}
                            validate={[Validator.required]}
                            normalize={(value, previousValue) => value.replace(/[^\d]/g, '')} // Ensure only numeric characters are allowed
                        />

                        <div id="phoneNumberErrorMessage" style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                            {this.props.invalid && this.props.invalid.PhoneNumber}
                        </div>
                        <Field fullWidth name="Address" component={TextField} hintText="Address" maxLength="100" floatingLabelText="Address" validate={[Validator.required]} />
                        <Field class="client-logo" name="logo" component={FileUpload} type="file" onChange={this.onFileChange} />
                        {imagePreview &&
                            <div>
                                <img alt="logo" src={imagePreview} className="imgPreview" /><br />
                            </div>
                        }
                        <div style={{ display: 'flex' }}>
                            <Button variant="contained" color="primary" type="submit">{id !== '0' ? 'Update' : 'Save'}</Button>&nbsp;&nbsp;
                            {id !== '0' && <Button variant="contained" color="primary" type="button" onClick={this.onDeleteClick} >Delete</Button>}&nbsp;&nbsp;
                            <Button variant="contained" color="primary" type="button" onClick={this.onCancelClick}>Cancel</Button>
                        </div>
                       
                    </form>
                </MuiThemeProvider>
            </DefaultLayout>
        )
    }
}

ClientForm = reduxForm({
    form: 'clientForm'
})(ClientForm);
function mapStateToProps(state) {
    return {
        data: state.clients.data && state.clients.data.data,
        saveClient: state.saveClient
    };
}
ClientForm = connect(mapStateToProps)(ClientForm);
export default ClientForm;