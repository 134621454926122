import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import Validator from '../Util/Validator';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { userAction } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import '../App.css';

class ForgotPasswordForm extends PureComponent {

    onSubmit = (values) => {
        values.action = 'forgotPassword';
        this.props.dispatch(userAction.request(values, null, null, (response) => {
            let { success, message } = response;
            swal({
                title: success ? 'Success' : "Error",
                text: message,
                icon: success ? "success" : "error"
            });
        }));
    }

    render() {
        let { handleSubmit, userAction } = this.props,
            isFetching = userAction.isFetching,
            path = `${window.location.origin}${window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))}/`;
        return (
            <>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        <Grid container spacing={3}>
                            <div className="login-grid">
                                <br />
                                <Grid item xs={12} align='center'>
                                    <img src={"./images/visitor.png"} width="20%" height="20%" alt='Visitor' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" color="inherit" align='center'>
                                        Account Recovery
                                         </Typography>
                                </Grid><br />
                                <Grid item xs={12} align='center'>
                                    Please enter your registered email address, we will send you account recovery link on your registered email.
                                </Grid><br />
                                <Grid item xs={12} align='center'>
                                    <Field name="Email" component={TextField} hintText="Email" floatingLabelText="Email" validate={[Validator.required, Validator.email]} />
                                </Grid><br />
                                <Grid item xs={12} align='center'>
                                    <Button variant="contained" color="primary" type="submit" className='login-button'>Send Link</Button>
                                </Grid><br />
                                <Grid item xs={12} align='center'>
                                    <Link href={path} variant="body2">
                                        Back to login
                                            </Link>
                                </Grid>
                            </div>
                        </Grid>
                    </form>
                </MuiThemeProvider>
            </>
        )
    }
}

ForgotPasswordForm = reduxForm({
    form: 'forgotPasswordForm'
})(ForgotPasswordForm);
function mapStateToProps(state) {
    return {
        userAction: state.userAction
    };
}
ForgotPasswordForm = connect(mapStateToProps)(ForgotPasswordForm);
export default ForgotPasswordForm;