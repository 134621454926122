import { loggedInUserDetail, setLogout } from '../reducer';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import * as ActionTypes from '../actions/httpRequest';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

export const rootReducer = combineReducers({
    loggedInUserDetail: loggedInUserDetail,
    visitors: ActionTypes.getVisitors.reducer,
    users: ActionTypes.getUsers.reducer,
    clients: ActionTypes.getClients.reducer,
    roles: ActionTypes.getRoles.reducer,
    form: formReducer,
    setLogout: setLogout,
    userAction: ActionTypes.userAction.reducer,
    saveClient: ActionTypes.saveClient.reducer,
    deleteClient: ActionTypes.deleteClient.reducer,
    loginAction: ActionTypes.loginAction.reducer,
    reLogin: ActionTypes.reLogin.reducer,
    employee: ActionTypes.employee.reducer,
    changePassword: ActionTypes.changePassword.reducer,
    emergencyNotification: ActionTypes.emergencyNotification.reducer,
    combo: ActionTypes.combo.reducer,
    emailTemplate: ActionTypes.emailTemplate.reducer
});

const composeEnhancers =
    typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunk)
);
const store = createStore(rootReducer, enhancer);

export default store;