import GridBase from '../components/GridBase';
import { connect } from 'react-redux';
import { getUsers } from '../redux/actions/httpRequest';
import util from '../Util/index';
import moment from 'moment';
class UserList extends GridBase {
    idColumn = "_id";
    title = 'Users';
    formRoute = util.route.addUser;
    showFormToSuperAdmin = true;
    defaultSort = 'Name';
    rowHeight = 40;
    hideColumn = true;
    sortOrder = 'asc';
    columns = [
        { headerName: "Name", field: "Name", width: 130 },
        { headerName: "Email", field: "Email", width: 220 },
        {
            headerName: "Role", field: "RoleId.Role", width: 130, valueGetter: (params) => {
                return params.row.RoleId?._id;
            }, type: 'singleSelect'
        },
        { headerName: "Client Name", field: "ClientId.ClientName", width: 150, valueGetter: (params) => params.row.ClientId?.ClientName },
        { headerName: "Created On", field: "createdAt", width: 150, renderCell: params => { return params.value ? moment(params.value).format(util.dateTimeFormatAmPm) : '' } },
        { headerName: "Updated On", field: "updatedAt", width: 150, renderCell: params => { return params.value ? moment(params.value).format(util.dateTimeFormatAmPm) : '' } }
    ]
}

UserList.defaultProps = {
    action: getUsers
};

function mapStateToProps(state) {
    return {
        data: state.users && state.users.data,
        loggedInUserDetail: state.loggedInUserDetail,
        roleData: state.roles && state.roles.data,
    }
};
var UserListModule = connect(mapStateToProps)(UserList);
export default UserListModule;