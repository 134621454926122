const appName = 'Visitor-App';
const exceptionBrowser = 'https://exceptionbrowser.spraxa.com/ExceptionHandler.ashx'; 
const Logger = {
    initializeLogger() {
		window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
			Logger.postWinErr(errorObj, errorMsg);
		}
	},
  postWinErr(errorObj) {
    const isSendError = true;
    if (window.location.hostname === 'localhost') {
      console.error(errorObj);
      return;
    }
    if (errorObj) {
      let metaData = {};
      metaData.MachineName = appName;
      metaData.DateTime = new Date().toISOString();
      metaData.DateTimeUTC = new Date().toISOString();
      metaData.RawUrl = window.location.href;
      const { message, stack, content } = errorObj;
      metaData.exception = [
        '<b>App Version:</b>' + '1.0.11',
        '<b>Message:</b> ' + message,
        '<b>Stack Trace:</b> ' + stack,
        '<b>Response Content:</b> ' + content,
      ].join('\r\n');
      metaData.UserAgent = navigator.userAgent;
      metaData.Browser = navigator.appVersion;
      metaData.VirtualPath = '/' + appName;
      metaData.SystemPath = appName;
      metaData.Location = appName;
      if (isSendError) {
        this.postError(metaData);
      } else {
        console.log(metaData); // In case of local, if error is coming, then print it on the console
      }
    }
  },

  postError(data) {
    fetch(exceptionBrowser, {
      method: 'POST',
      body: this.getFormData(data, false, ''),
    })
      .then((response) => response.text())
      .then((responsetext) => {
        console.log(responsetext);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  getFormData(data, forSort, ignoreParams) {
    let formData = new FormData();
    if (typeof data === 'object') {
      Object.keys(data).forEach(function (key) {
        if (!ignoreParams || ignoreParams.indexOf(key) == -1) {
          let value = data[key];
          if (typeof value == 'object') {
            if (forSort && value) {
              let innerKey = Object.keys(value)[0];
              formData.append(key + '[' + innerKey + ']', value[innerKey]); // need data in form like sort[Date]=desc
            } else {
              formData.append(key, JSON.stringify(value));
            }
          } else {
            formData.append(key, value);
          }
        }
      });
    }
    return formData;
  },
};

module.exports = Logger;
