import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { emergencyNotification } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader';
import DefaultLayout from '../components/DefaultLayout';
import Validator from '../Util/Validator';
import '../App.css';

class EmergencyNotification extends PureComponent {

    onEmergencyNotification = (values) => {
        let filters = [{ operator: 'equal', type: 'date', value: new Date(), property: 'CheckIn', expression: 'and' },
        { operator: 'equal', type: 'date', value: null, property: 'CheckOut', expression: 'and' }];
        values.filters = filters;
        this.props.dispatch(emergencyNotification.request({ params: values }, null, null, (response) => {
            let { success, message } = response;
            swal({
                title: success ? "Success" : "Error",
                text: message,
                icon: success ? "success" : "error"
            });
        }));
    }

    render() {
        let { handleSubmit, emergencyNotification } = this.props,
            isFetching = emergencyNotification.isFetching;
        const title = "Emergency Notification";
        return (
            <DefaultLayout title={title}>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <form onSubmit={handleSubmit(this.onEmergencyNotification)} >
                        <Field fullWidth name="emergencyMessage" component={TextField} multiLine={true}  hintText="Emergency Message" floatingLabelText="Emergency Message" validate={[Validator.required]} />
                        <Button variant="contained" color="primary" type="submit">Send Notification</Button>
                    </form>
                </MuiThemeProvider>
            </DefaultLayout>
        )
    }
}

EmergencyNotification = reduxForm({
    form: 'emergencyNotificationForm'
})(EmergencyNotification);
function mapStateToProps(state) {
    return {
        emergencyNotification: state.emergencyNotification,
    };
}
EmergencyNotification = connect(mapStateToProps)(EmergencyNotification);
export default EmergencyNotification;