const drawerWidth = 240;

export const styles = (theme) => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        //minHeight: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            width: `100%`,
        },
    },
    appBarShift: {
        position:'relative',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        width:"102%",
        margin:"-87.6px 0px 15px -23px"
        
        
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        overflow: 'hidden',
        position: 'relative',
        top: 0,
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
       
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minHeight: '-webkit-fill-available',
        height: 'initial',
        flex: '1 1 auto',
        overflowY: 'scroll',
       
    },
    button: {
        margin: theme.spacing(),
    },
    link: {
        textDecoration: 'none',
    },
    current: {
        color: 'red !important',
    },
    notifications: {
        overflowX: 'hidden'
    },
    fillSpace: {
        flex: '1 1 auto'
    }
});