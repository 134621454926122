
let utils = {
    dateTimeFormatAmPm: "MM/DD/YYYY HH:mm:ss",
    dateTimeFormat: "MM/DD/YYYY HH:mm",
    dateFormat: "MM/DD/YYYY",
    route: {
        visitor: '/visitor',
        client: '/client',
        user: '/user',
        settings: '/settings',
        addClient: '/client/',
        addUser: '/user/',
        scheduleVisit: '/visitor/'
    },
    visitStatus: {
        onGoing: "OnGoing",
        completed: "Completed",
        scheduled: "Scheduled",
        reScheduled: "Rescheduled"
    }
}

export default utils;