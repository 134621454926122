import * as React from 'react';
import VisitorList from '../screens/VisitorList';
import ClientList from '../screens/ClientList';
import UsersList from '../screens/User';
import AddClient from '../screens/ClientForm';
import AddUser from '../screens/UserForm';
import enums from '../Util/consts';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import ScheuleVisit from '../screens/ScheduleVisit';
import Settings from '@material-ui/icons/Settings';
import ClientSettings from '../screens/Settings';
import EmergencyNotification from '../screens/EmergencyNotification';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
const { SuperAdmin, ClientAdmin, User } = enums.Roles;

let Routes = [
    { path: '/visitor', title: "Visitors", exact: true, component: VisitorList, permission: [SuperAdmin, ClientAdmin, User], isMenu: true, icon: () => <DirectionsWalkIcon /> },
    { path: '/client', title: "Clients", exact: true, component: ClientList, permission: [SuperAdmin], isMenu: true, icon: () => <BusinessIcon /> },
    { path: '/user', title: "Users", exact: true, component: UsersList, permission: [SuperAdmin, ClientAdmin], isMenu: true, icon: () => <PeopleIcon /> },
    { path: '/settings', title: "Settings", exact: true, component: ClientSettings, permission: [SuperAdmin, ClientAdmin, User], isMenu: true, icon: () => <Settings /> },
    { path: '/emergencyNotification', title: "Emergency", exact: true, component: EmergencyNotification, permission: [SuperAdmin, ClientAdmin, User], isMenu: true, icon: () => <NotificationImportant /> },
    { path: '/client/:id', exact: true, title: "Add Client", component: AddClient, permission: [SuperAdmin] },
    { path: '/user/:id', exact: true, title: "Add User", component: AddUser, permission: [SuperAdmin, ClientAdmin] },
    { path: '/visitor/:id/:visitId?', exact: true, title: "Shedule Visit", component: ScheuleVisit, permission: [ SuperAdmin, ClientAdmin] }
];
export default Routes;