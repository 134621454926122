import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { styles } from '../navigation/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { userAction, getRoles } from '../redux/actions/httpRequest';
import { loggedInUserDetail, setLogout } from '../redux/actions';
import Loader from '../components/Loader'
const classNames = require('classnames');

class DefaultLayout extends React.PureComponent {

    handleLogout = () => {
        const me = this;
        me.props.dispatch(userAction.request({
            action: 'logout'
        }, null, null, function (response) {
            if (response && response.success) {
                me.props.dispatch(loggedInUserDetail({}));
                me.props.dispatch(setLogout(true));
                me.props.dispatch(getRoles.dispose());
            }
        }));
    }

    render() {
        const { title, children, classes, userInfo, userAction } = this.props;
        let { Name, ClientId } = userInfo.user;
        if (ClientId && ClientId.ClientName) {
            Name = Name + " (" + ClientId.ClientName + ")";
        }
        let isFetching = userAction.isFetching;
        return (
            <>
                {isFetching && <Loader isOpen={isFetching} />}
                <AppBar
                   
                    className={classNames( classes.appBarShift)}
                >
                    <Toolbar>
                        <Typography className={classes.fillSpace} variant="h6" color="inherit" noWrap={true}>
                            {title}
                        </Typography>
                        <div><div>{Name}</div></div>&nbsp;&nbsp;
                        <div onClick={this.handleLogout} className="logout">
                            <img alt="logout" src={"./images/logout.png"} />
                        </div>
                    </Toolbar>
                </AppBar>
                {children}
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.loggedInUserDetail,
        userAction: state.userAction
    }
}
var defaultLayoutModule = connect(mapStateToProps)(DefaultLayout);
export default withStyles(styles, { withTheme: true })(defaultLayoutModule);