import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { changePassword } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader';
import DefaultLayout from '../components/DefaultLayout';
import Validator from '../Util/Validator';
import '../App.css';

class GeneralSettings extends PureComponent {

    onChangePassword = (values) => {
        values.action = 'changePassword';
        this.props.dispatch(changePassword.request(values, null, null, (response) => {
            let { success, message } = response;
            swal({
                title: success ? "Success" : "Error",
                text: message,
                icon: success ? "success" : "error"
            }).then(() => {
                this.props.reset();
            })
        }));
    }

    render() {
        let { handleSubmit, saveClient, changePassword } = this.props,
            isFetching = saveClient.isFetching || changePassword.isFetching;
        const title = "Update Settings";
        return (
            <div>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <div className='changePasswordTitle'>Change Password</div>
                    <form onSubmit={handleSubmit(this.onChangePassword)} >
                        <Field fullWidth name="OldPassword" type="password" component={TextField} maxLength="20" hintText="Old Password" floatingLabelText="Old Password" validate={[Validator.required]} />
                        <Field fullWidth name="NewPassword" type="password" component={TextField} maxLength="20" hintText="New Password" floatingLabelText="New Password" validate={[Validator.required, Validator.passwordComplexity]} />
                        <Field fullWidth name="ConfirmPassword" type="password" component={TextField} maxLength="20" hintText="Confirm Password" floatingLabelText="Confirm Password" validate={[Validator.required, Validator.passwordMatch]} />
                        <Button variant="contained" color="primary" type="submit">Change Password</Button>
                    </form>
                </MuiThemeProvider>
                </div>
        )
    }
}

GeneralSettings = reduxForm({
    form: 'generalSettingsForm'
})(GeneralSettings);
function mapStateToProps(state) {
    return {
        saveClient: state.saveClient,
        changePassword: state.changePassword,
    };
}
GeneralSettings = connect(mapStateToProps)(GeneralSettings);
export default GeneralSettings;