const baseUrl = process.env.REACT_APP_BASE_URL;
export default {
	baseUrl: baseUrl,
	LOGIN: baseUrl + "login",
	RE_LOGIN: baseUrl + "reLogin",
	LOGOUT: baseUrl + "logout",
	VISITORLIST: baseUrl + "visitorsList",
	CLIENTLIST: baseUrl + "clientsList",
	ROLELIST: baseUrl + "rolesList",
	GET_USER: baseUrl + "userList",
	USER_ACTION: baseUrl + "user",
	SAVE_CLIENT: baseUrl + "saveUpdateClientData",
	DELETE_CLIENT: baseUrl + "deleteClient",
	SCHEDULE_VISIT: baseUrl + "saveVisitorData",
	CHANGE_PASSWORD: baseUrl + "user",
	EMERGENCY_NOTIFICATION: baseUrl + "emergencyNotification",
	CANCEL_VISIT: baseUrl + "cancelVisit",
	COMBO: baseUrl + "combo",
	EMAIL_TEMPLATE: baseUrl + "emailTemplate"
}