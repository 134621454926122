import React, { PureComponent, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@material-ui/core';
import DefaultLayout from '../DefaultLayout';
import enums from '../../Util/consts';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import util from '../../Util/index';
import '../../App.css';
import api from '../../redux/httpUtil/serverApi';
import { getRoles, getVisitors, scheduleVisit } from '../../redux/actions/httpRequest';
import swal from 'sweetalert';
import moment from 'moment';
import { TablePaginationActions } from '../../Util/GridUtil';
class GridBase extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false, isLogo: true, checkoutDialogOpen: false, visitorToCheckout: null, requestOption: {
                pageSize: 10,
                pageNo: 0,
                orderBy: this.defaultSort,
                dir: this.sortOrder,
                filters: null
            }
        }
        this.onRowClick = this.onRowClick.bind(this);
        this.openForm = this.openForm.bind(this);
        this.onOrderChange = this.onOrderChange.bind(this);
        this.serverRequest = this.serverRequest.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }
    componentWillMount() {
        if (!this.props.roleData) {
            this.props.dispatch(getRoles.request({}));
        }
        this.serverRequest();
    }
    openForm(id, visitId) {
        if (visitId) {
            this.props.history.push(this.formRoute + (typeof (id) === 'string' ? id : 0) + '/' + visitId);
        } else {
            this.props.history.push(this.formRoute + (typeof (id) === 'string' ? id : 0));
        }

    }
    updateColumnsWithDynamicProps(roleData) {
        if (!roleData) return this.columns;
    
        return this.columns.map((col) => {
            if (col.field === "RoleId.Role") {
                let updatedCol = { ...col };
                updatedCol.valueOptions = roleData.map(role => ({
                    value: role._id,
                    label: role.Role 
                }));
                return updatedCol;
            }
            return col;
        });
    }
    onRowClick(params, event) {
        const { row: rowData } = params;
        if (rowData.Status !== util.visitStatus.scheduled && rowData.Status !== util.visitStatus.reScheduled && this.fromVisitor) {
            return;
        }
        const me = this;
        if (me.formRoute && me.formRoute !== '') {
            me.clicked = false;
            me.clickedTimeout && clearTimeout(me.clickedTimeout);
            if (rowData.VisitId) {
                me.openForm(rowData._id, rowData.VisitId);
            } else {
                me.openForm(rowData._id);
            }
        }
        else {
            me.clicked = true;
            me.clickedTimeout = setTimeout(() => {
                me.clicked = false;
            }, 300);
        }
    }
    serverRequest = () => {
        if (!this.state.requestOption.orderBy && this.defaultSort) {
            this.state.requestOption.orderBy = this.defaultSort;
        }
        this.props.dispatch(this.props.action.request({ params: this.state.requestOption }));
    };
    onchangeHadler = (params) => {
        const { pageNo, pageSize } = this.state.requestOption;
        const newPageNo = params.pagination.paginationModel.page;
        const newPageSize = params.pagination.paginationModel.pageSize;

        if (newPageNo !== pageNo || newPageSize !== pageSize) {
            // Page number has changed, call serverRequest
            this.setState(
                (prevState) => ({
                    requestOption: {
                        ...prevState.requestOption,
                        pageNo: newPageNo,
                        pageSize: params.pagination.paginationModel.pageSize,
                    },
                }),
                () => this.serverRequest()
            );
        } else {
            // Page number is the same, no need to call serverRequest
            return;
        }
    };


    onFilterChange = (filters) => {
        const { items } = filters;

        let filter = [];
        if (items && items.length > 0) {
            items.forEach(element => {
                if (!element.value) {
                    return;
                }
                let index = filter.findIndex(filter => { return filter.property === element.field });
                if (index > -1) {
                    filter.splice(index, 1);
                }
                filter.push({ operator: 'equal', type: element.type === 'singleSelect' ? 'objectId' : element.field, value: element.value, property: element.field, expression: filters.logicOperator });
            });
        }
        this.setState(
            (prevState) => ({
                requestOption: {
                    ...prevState.requestOption,
                    filters: filter
                },
            }),
            () => this.serverRequest()
        );
    }

    onOrderChange(params) {
        if (params.length <= 0) {
            this.setState(
                (prevState) => ({
                    requestOption: {
                        ...prevState.requestOption,
                        orderBy: this.defaultSort,
                        dir: this.sortOrder
                    },
                }),
                () => this.serverRequest()
            );
            return;
        }
        const { field, sort } = params[0];
        this.setState(
            (prevState) => ({
                requestOption: {
                    ...prevState.requestOption,
                    orderBy: field,
                    dir: sort
                }
            }),
            () => this.serverRequest()
        );
    }

    getRowClassName = (params) => {
        return 'custom-row-class';
    };

    openDialog = (rowData) => {
        let { _id, FullName, CompanyName, PersonToMeet, PhoneNumber, Reason, ClientId } = rowData;
        this.setState({
            open: true, id: _id, fullName: FullName, companyName: CompanyName, phoneNumber: PhoneNumber, reason: Reason, personToMeet: PersonToMeet, clientId: ClientId
        });
    }

    handlePrint = () => {
        window.printJS({
            printable: 'card',
            type: 'html',
            style: `.card { border: 1px solid black; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);   margin: auto; max-height: 300px; max-width: 200px; text-align: center;  font-family: arial;}.img {transform: 'rotate(270deg)'; max-width:100px;max-height: 100px} .client-logo{ max-width:50px;max-height: 50px} .info{font-size:12px;}`
        })
        this.handleClose();
    }

    handleClose = () => {
        this.setState({ open: false, id: null, fullName: null, companyName: null, phoneNumber: null, reason: null, personToMeet: null, clientId: null });
    }

    onError = () => {
        // throw new Error("tesing error email")
        this.setState({ isLogo: false });
    }
    openCheckoutDialog = (rowData) => {
        this.setState({ checkoutDialogOpen: true, visitorToCheckout: rowData });
    }

    closeCheckoutDialog = () => {
        this.setState({ checkoutDialogOpen: false, visitorToCheckout: null });
    }

    confirmCheckout = () => {
        const { visitorToCheckout } = this.state;

        if (visitorToCheckout && visitorToCheckout._id) {
            const values = {
                _id: visitorToCheckout._id,
                Status: "Admin-Check-Out",
                CheckOutTime: moment().valueOf()
            };
            this.setState({ isFetching: true });
            this.props.dispatch(scheduleVisit.request(values, null, null, (response) => {
                let { success, message } = response;
                if (success) {
                    this.setState({ isFetching: false });
                    this.serverRequest();
                } else {
                    swal({
                        title: "Error",
                        text: message,
                        icon: "error"
                    });
                }
            }));
        }

        this.closeCheckoutDialog();
    }


    render() {

        let { data, loggedInUserDetail,roleData  } = this.props;
        const { open, id, fullName, companyName, personToMeet, phoneNumber, reason, clientId, isLogo } = this.state;
        let columns = this.columns;
        const { hideColumn, showFormToSuperAdmin, title, formRoute, openForm, onRowClick, onOrderChange, onHandleChangeListPage, handleChangeRowsPerPage, onFilterChange, requestOption, addButtonText } = this;
        if (!data || !data.data || data.data.constructor !== Array) {
            data = { data: [] };
        }
        const { pageNo, pageSize } = this.state.requestOption;
        let isFetching = data.isFetching;
        const role = loggedInUserDetail && loggedInUserDetail.user && loggedInUserDetail.user.RoleId ? loggedInUserDetail.user.RoleId.Role : loggedInUserDetail && loggedInUserDetail.user.Role ? loggedInUserDetail.user.Role : '';
        if (hideColumn && role !== enums.Roles.SuperAdmin) {
            columns = columns.filter(rec => rec.title !== 'Client Name');
        }
        let updatedColumns = this.updateColumnsWithDynamicProps(roleData);
        let showAddButton = formRoute && formRoute !== '';
        if ((!showFormToSuperAdmin && (role==enums.Roles.User || role === enums.Roles.SuperAdmin))  ) {
            showAddButton = false;
        }

        let logoPath = api.baseUrl.replace('/api', ''),
            logoUrl = logoPath + clientId + '.png';
        return (
            <DefaultLayout title={title}>
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">{"Print Badge"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div class="card" id="card">
                                {isLogo && <img src={`${logoUrl}`} alt='logo' style={{ width: 100, height: 100, paddingTop: 1 }} className="client-logo" onError={this.onError} />} <br />
                                <img src={`${api.baseUrl}getFace/${id}/?v=${Date.now()}`} alt='face' style={{ width: 200, height: 200 }} className="img" />
                                <div> <b>{fullName}</b></div>
                                <div className="info">Company - {companyName}</div>
                                <div className="info">Person To Meet - {personToMeet}</div>
                                <div className="info">Phone - {phoneNumber}</div>
                                <div className="info">Reason - {reason}</div><br />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} variant="outlined" color="secondary" autoFocus>
                            Cancel
                        </Button>
                        <Button onClick={this.handlePrint} variant="outlined" color="primary" autoFocus>
                            Print
                        </Button>

                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.checkoutDialogOpen}
                    onClose={this.closeCheckoutDialog}
                >
                    <DialogTitle>{"Checkout Confirmation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to check out {this.state.visitorToCheckout && this.state.visitorToCheckout.FullName}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeCheckoutDialog} color="secondary">
                            No
                        </Button>
                        <Button onClick={this.confirmCheckout} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <React.Fragment>
                    {showAddButton ? <div><Button variant="contained" color="primary" type="button" onClick={openForm}>{addButtonText ? addButtonText : 'Add'}</Button><br /><br /></div> : null}
                    <div style={{ height: 'auto', width: '100%', boxShadow: "0px 0px 2px 1px grey", padding: "2px", borderRadius: "2px" }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            rows={data.data}
                            columns={updatedColumns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: pageNo, pageSize: pageSize },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, 50, 100]}
                            rowCount={data.total}
                            rowHeight={this.rowHeight || 60}
                            paginationMode="server"
                            loading={isFetching}
                            onStateChange={(params) => {
                                this.onchangeHadler(params);
                            }}
                            style={{ color: "black", fontWeight: "500", border: "none" }}
                            onRowDoubleClick={onRowClick}
                            onSortModelChange={(params) => onOrderChange(params)}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            componentsProps={{
                                pagination: {
                                    ActionsComponent: TablePaginationActions	// for first and last button
                                }
                            }}
                        />
                    </div>
                </React.Fragment>
            </DefaultLayout>
        );
    }
}
export default GridBase;