import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import MenuItem from 'material-ui/MenuItem';
import { TextField, SelectField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import Validator from '../Util/Validator';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { getClients, getRoles, userAction } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader'
import util from '../Util/index';
import DefaultLayout from '../components/DefaultLayout';
import enums from '../Util/consts';

class UserForm extends PureComponent {
    componentWillMount() {
        let { loggedInUserDetail } = this.props;
        const role = loggedInUserDetail.user && loggedInUserDetail.user.Role ? loggedInUserDetail.user.Role : loggedInUserDetail.user.RoleId.Role;
        if (role !== enums.Roles.ClientAdmin) {
            this.props.dispatch(getClients.request({ fromCombo: true }));
        }
        if (!this.props.roleData) {
            this.props.dispatch(getRoles.request({}));
        }
        const id = this.props.match.params.id;
        if (id && id !== '0' && this.props.data) {
            const data = this.props.data.data.filter(e => { return e._id === id });
            const { Name, Email, ClientId, RoleId } = data[0];
            this.props.initialize({ Name: Name, Email: Email, ClientName: ClientId && ClientId.ClientName, Password: "*****", ClientId: ClientId && ClientId._id, RoleId: RoleId && RoleId._id, Role: RoleId && RoleId.Role });
        }
    }
    onSubmit = (values) => {
        const me = this;
        const id = this.props.match.params.id;
        if (id && id !== '0') {
            values.id = id;
        }
        values.action = 'save';
        me.props.dispatch(userAction.request(values, null, null, (response) => {
            let { success, message } = response;
            if (success) {
                me.props.history.push(util.route.user);
            } else {
                swal({
                    title: "Error",
                    text: message,
                    icon: "error"
                });
            }
        }));
    }

    onCancelClick = () => {
        this.props.reset();
        this.props.history.push(util.route.user);
    }

    onDeleteClick = () => {
        const id = this.props.match.params.id,
            me = this;
        if (id && id !== '0') {
            const params = { action: 'delete', id: id };
            me.props.dispatch(userAction.request(params, null, null, (response) => {
                const { success } = response;
                if (success) {
                    me.props.history.push(util.route.user);
                }
            }));
        }
    }

    render() {
        const { handleSubmit, clientData, match, userAction, roleData, loggedInUserDetail, userFormValues } = this.props,
            id = match.params.id,
            isFetching = userAction.isFetching;
        const newUser = id && id === '0';
        const title = newUser ? "Add User" : "Update User";
        const { SuperAdmin } = enums.Roles;
        const role = loggedInUserDetail.user && loggedInUserDetail.user.Role ? loggedInUserDetail.user.Role : loggedInUserDetail.user.RoleId.Role;
        let showClientField = role === SuperAdmin;
        if (showClientField && userFormValues && userFormValues.RoleId && roleData) {
            const selectedRole = roleData.find(rec => rec._id === userFormValues.RoleId);
            showClientField = selectedRole && selectedRole.Role !== SuperAdmin;
        }
        const canDelete = !newUser && id !== loggedInUserDetail.user._id;
        return (
            <DefaultLayout title={title}>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <form onSubmit={handleSubmit(this.onSubmit)} >
                        <Field fullWidth name="Name" autoComplete='off' component={TextField} hintText="Name" maxLength="50" floatingLabelText="Name" validate={[Validator.required]} />
                        <Field fullWidth name="Email" autoComplete='off' component={TextField} hintText="Email" floatingLabelText="Email" validate={[Validator.required, Validator.email]} />
                        <Field fullWidth name="Password" autoComplete='off' type="password" component={TextField} maxLength="20" hintText="Password" floatingLabelText="Password" validate={[Validator.required, Validator.passwordComplexity]} />
                        <Field fullWidth name="RoleId" component={SelectField} hintText="Role" floatingLabelText="Role" validate={[Validator.required]} >
                            {roleData && roleData.filter(option => !(role === enums.Roles.ClientAdmin && option.Role === SuperAdmin)).map(option => (
                                <MenuItem primaryText={option.Role} key={option._id} value={option._id}> </MenuItem>
                            ))}
                        </Field>

                        {showClientField ?
                            <Field fullWidth name="ClientId" component={SelectField} hintText="Client" floatingLabelText="Client" validate={[Validator.required]}>
                                {clientData && clientData.data.map(option => (
                                    <MenuItem primaryText={option.ClientName} key={option._id} value={option._id}> </MenuItem>
                                ))}
                            </Field> : null
                        }

                        <div style={{ display: 'flex' }}>
                            <Button variant="contained" color="primary" type="submit">{!newUser ? 'Update' : 'Save'}</Button>&nbsp;&nbsp;
                            {canDelete && <Button variant="contained" color="primary" type="button" onClick={this.onDeleteClick} >Delete</Button>}&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="contained" color="primary" type="button" onClick={this.onCancelClick}>Cancel</Button>
                        </div>
                    </form>
                </MuiThemeProvider>
            </DefaultLayout >
        )
    }
}

UserForm = reduxForm({
    form: 'userForm'
})(UserForm);
function mapStateToProps(state) {
    const { userForm } = state.form;
    return {
        clientData: state.clients && state.clients.data,
        data: state.users && state.users.data,
        roleData: state.roles && state.roles.data,
        loggedInUserDetail: state.loggedInUserDetail,
        userAction: state.userAction,
        userFormValues: userForm && userForm.values
    };
}
UserForm = connect(mapStateToProps)(UserForm);
export default UserForm;