import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';
import { loginAction, reLogin } from '../redux/actions/httpRequest'
import { loggedInUserDetail, setLogout } from '../redux/actions'
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Loader from '../components/Loader'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import '../App.css';

class Login extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            errorMessage: '',
            loading: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.onUserNameChange = this.onChange.bind(this, true);
        this.onPasswordChange = this.onChange.bind(this, false);
    }
    onChange(isUserName, event, newValue) {
        if (isUserName) {
            this.setState({ username: newValue, errorMessage: '' });
        } else {
            this.setState({ password: newValue, errorMessage: '' });
        }
    }
    componentWillMount() {
        const me = this;
        me.props.dispatch(reLogin.request({
            userName: "",
            password: ""
        }, null, null, function (response) {
            if (response && response.success) {
                me.props.dispatch(loggedInUserDetail(response));
                me.props.history.push('/visitor');
            } else if (!response.fromReload) {
                me.setState({ errorMessage: "Invalid username or password" });
            }
        }));
    }
    handleClick(event) {
        const { username, password } = this.state;
        const me = this;
        if (username === '' || password === '') {
            me.setState({ errorMessage: "Username and password required." });
            return;
        }
        me.setState({ loading: true });
        me.props.dispatch(loginAction.request({
            userName: username,
            password: password
        }, null, null, function (response) {
            if (response && response.success) {
                me.props.dispatch(loggedInUserDetail(response));
                me.props.dispatch(setLogout(false));
                me.setState({ loading: false });
                if (me.props.match.params && me.props.match.params.redirectTo) {
                    me.props.history.push('/' + me.props.match.params.redirectTo);
                } else {
                    me.props.history.push('/visitor');
                }
            } else {
                me.setState({ errorMessage: "Invalid username or password", loading: false });
            }
        }));
    }
    render() {
        const { onUserNameChange, onPasswordChange } = this;
        const { errorMessage } = this.state;
        return (
            <>
                {this.state.loading && <Loader isOpen={this.state.loading} />}
                <div>
                    <MuiThemeProvider>
                        <form>
                            <Grid container spacing={3}>
                                <div className="login-grid">
                                    <br />
                                    <Grid item xs={12} align='center'>
                                        <img alt="visitor" src={"./images/visitor.png"} width="20%" height="20%" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" color="inherit" align='center'>
                                            Welcome
                                        </Typography>
                                    </Grid><br />
                                    <Grid item xs={12} align='center'>
                                        <TextField
                                            hintText="Enter your Email"
                                            floatingLabelText="Email"
                                            onChange={onUserNameChange}
                                        />
                                    </Grid><br />
                                    <Grid item xs={12} align='center'>
                                        <TextField
                                            type="password"
                                            hintText="Enter your Password"
                                            floatingLabelText="Password"
                                            onChange={onPasswordChange}
                                        />
                                    </Grid><br />
                                    <Grid item xs={12} align='center'>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className='login-button'
                                            onClick={this.handleClick}
                                        >Login
                                        </Button>
                                    </Grid><br />
                                    <Grid item xs={12} align='center'>
                                        <Link href="#/forgotPassword" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} align='center'>
                                        <Typography className="loginerror" variant="h6" color="inherit" noWrap={true}>
                                            {errorMessage}
                                        </Typography>
                                    </Grid>
                                </div>
                            </Grid>
                        </form>
                    </MuiThemeProvider>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
    }
}
export default connect(mapStateToProps)(Login);