export let actionType = {
    LOGED_IN_USER_DETAIL: 'LOGED_IN_USER_DETAIL',
    CASE_GRID_DATA: 'CASE_GRID_DATA',
    VISITOR_LIST_DATA: 'VISITOR_LIST_DATA',
    VISITOR_LIST_DATA_SUCCESS: 'VISITOR_LIST_DATA_SUCCESS',
    CLIENT_LIST_DATA: 'CLIENT_LIST_DATA',
    CLIENT_LIST_DATA_SUCCESS: 'CLIENT_LIST_DATA_SUCCESS',
    ROLE_LIST_DATA: 'ROLE_LIST_DATA',
    ROLE_LIST_DATA_SUCCESS: 'ROLE_LIST_DATA_SUCCESS',
    SET_LOGOUT: 'SET_LOGOUT',
    USER_ACTION: 'USER_ACTION',
    SAVE_CLIENT: 'SAVE_CLIENT',
    UPDATE_USER: 'UPDATE_USER',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    VALIDATE_LINK: 'VALIDATE_LINK',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SCHEDULE_VISIT: 'SCHEDULE_VISIT',
    GET_USER: 'GET_USER',
    EMPLOYEE: 'EMPLOYEE',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    COMBO:'COMBO',
    EMAIL_TEMPLATE:'EMAIL_TEMPLATE'
};


export let loggedInUserDetail = (value) => {
    return {
        type: actionType.LOGED_IN_USER_DETAIL,
        value
    };
}
export let setLogout = (value) => {
    return {
        type: actionType.SET_LOGOUT,
        value
    };
}