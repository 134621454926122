import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'redux-form-material-ui';
import { Button } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { getClients, saveClient } from '../redux/actions/httpRequest';
import swal from 'sweetalert';
import Loader from '../components/Loader'
import DefaultLayout from '../components/DefaultLayout';
import FileUpload from '../components/FileUpload';
import api from '../redux/httpUtil/serverApi';
import Validator from '../Util/Validator';
import '../App.css';

class PreferenceSettings extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imagePreview: null
        }
    }

    componentDidMount() {
        const { loggedInUserDetail } = this.props;
        if (loggedInUserDetail && loggedInUserDetail.ClientId) {
            const path = api.baseUrl;
            const clientId = loggedInUserDetail.ClientId && loggedInUserDetail.ClientId._id;
            const imageUrl = path + clientId + '.png';
            this.setState({
                imagePreview: imageUrl
            });
            const me = this;
            let filters = [], params = {};
            filters.push({ operator: 'equal', type: 'objectId', value: loggedInUserDetail.ClientId._id, property: '_id', expression: 'and' })
            params.filters = filters;
            me.props.dispatch(getClients.request(params, null, null, (response) => {
                if (response && response.data && response.data.length > 0) {
                    let { WelcomeMessage, CheckInMessage, ClientName, EmailsForCheckInCheckOut, Email } = response && response.data && response.data[0];
                    me.props.initialize({ WelcomeMessage: WelcomeMessage ? WelcomeMessage : `Welcome to ${ClientName}`, CheckInMessage: CheckInMessage ? CheckInMessage : "Thanks for visiting us.", EmailsForCheckInCheckOut: EmailsForCheckInCheckOut ? EmailsForCheckInCheckOut : Email });
                }
            }));
        }
    }

    onSubmit = (values) => {
        const { loggedInUserDetail } = this.props;
        if (loggedInUserDetail && loggedInUserDetail.ClientId) {
            values.fromSettings = true;
            values.id = loggedInUserDetail.ClientId._id;
            this.props.dispatch(saveClient.request(values, null, null, (response) => {
                let { success, message } = response;
                swal({
                    title: success ? "Success" : "Error",
                    text: message,
                    icon: success ? "success" : "error"
                }).then((value) => {
                    if (success) {
                        window.location.reload();
                    }
                });
            }));
        }
    }

    onFileChange = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                imagePreview: reader.result
            });
        }
    }

    render() {
        let { handleSubmit, saveClient } = this.props,
            isFetching = saveClient.isFetching;
        const title = "Update Settings";
        let { imagePreview } = this.state;
        return (
            <div>
                {isFetching && <Loader isOpen={isFetching} />}
                <MuiThemeProvider>
                    <div className='changePasswordTitle'>Preferences</div>
                    <form onSubmit={handleSubmit(this.onSubmit)} >
                        <Field fullWidth name="WelcomeMessage" component={TextField} multiLine={true} hintText="Welcome Message" maxLength="300" floatingLabelText="Welcome Message" />
                        <Field fullWidth name="CheckInMessage" component={TextField} multiLine={true} hintText="Check In Message" maxLength="300" floatingLabelText="Check In Message" />
                        <Field fullWidth name="EmailsForCheckInCheckOut" component={TextField} multiLine={true} hintText="Emails To Receive Check In/Check Out Details" maxLength="300" floatingLabelText="Emails To Receive Check In/Check Out Details" validate={[Validator.multipleEmailValidator]} />
                        <p><b>Note- </b>Enter multiple email by comma seperated.</p>
                        <Field class="client-logo" name="logo" component={FileUpload} type="file" onChange={this.onFileChange} />
                        {imagePreview &&
                            <div>
                                <img src={imagePreview} className="imgPreview" alt='Preview' /><br />
                            </div>
                        }
                        <div style={{ display: 'flex' }}>
                            <Button variant="contained" color="primary" type="submit">{'Update'}</Button>&nbsp;&nbsp;
                        </div>
                    </form>
                </MuiThemeProvider>
                </div>
        )
    }
}

PreferenceSettings = reduxForm({
    form: 'preferenceSettingsForm'
})(PreferenceSettings);
function mapStateToProps(state) {
    return {
        saveClient: state.saveClient,
        loggedInUserDetail: state.loggedInUserDetail.user
    };
}
PreferenceSettings = connect(mapStateToProps)(PreferenceSettings);
export default PreferenceSettings;