import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2
    }
});

class Loader extends PureComponent {

    render() {
        const { classes, isOpen, title } = this.props;
        return (
            <>
                {
                    isOpen && <Dialog open={true} aria-labelledby="simple-dialog-title" PaperProps={{
                        style: { backgroundColor: 'transparent', boxShadow: 'none', fontSize: '25px' },
                    }}>
                        <center>
                            <CircularProgress className={classes.progress} color="primary" />
                            {title && <p className="loader">{title}</p>}
                        </center>
                    </Dialog>
                }
            </>
        )
    }
}

Loader.defaultProps = {
    title: 'Loading...'
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Loader);